@import '../../../../styles/Variables.module'

.grid-card--image
    display: grid
    grid-gap: 3rem
    grid-template-columns: repeat(3, 1fr)

    .card
        display: flex
        flex-direction: column
        background: $white
        background-size: cover
        background-position: center
        border-radius: 1.2rem
        overflow: hidden
        box-shadow: 0 0.4rem 1.6rem 0 rgba($blue, 0.25)

        &:hover
            .image
                img
                    transform: scale(1.1)

        .image
            max-height: 27.5rem
            overflow: hidden

            img
                width: 100%
                object-fit: cover
                aspect-ratio: 4 / 3
                transition: $transition3s

        .card--details
            flex-grow: 1
            display: flex
            flex-direction: column
            padding: 2rem

        .title
            min-height: 4.5rem
            font-family: $fontMontserrat
            font-size: 1.8rem
            font-weight: 600
            margin-bottom: 1.6rem

        .card-icon
            width: 1.4rem
            color: $blue
            font-size: 1.6rem
            text-align: center
            margin-right: 1rem

        .destination, .valabilities, .days
            font-family: $fontMontserrat
            font-size: 1.3rem
            font-weight: 500

        .destination, .days
            margin-bottom: .5rem

        .valabilities
            flex-grow: 1
            display: flex
            flex-wrap: wrap
            line-height: 1.5

            .date
                &:not(:last-child)::after
                    content: ','
                    margin-right: .5rem

        .price
            font-size: 2.4rem
            font-weight: 600
            text-align: right
            margin-top: 1.5rem

            .from
                font-size: 1.4rem
                margin-right: .6rem

        .button
            color: $white
            background: $blue-lighter
            font-size: 1.4rem
            font-weight: 500
            text-align: center
            padding: 1.55rem
            transition: $transition2s

            &:hover
                color: $white
                background: $blackishBlue
                text-decoration: unset

@media all and (max-width: 768px)
    .grid-card--image
        grid-template-columns: repeat(2, 1fr)

@media all and (max-width: 550px)
    .grid-card--image
        grid-template-columns: auto