@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap')

// container sizez
$pageWidth: 122rem
$landingBanner: 80vh

// font families
$fontHankenGrotesk: 'Hanken Grotesk', sans-serif
$fontMontserrat: 'Montserrat', sans-serif
    
// font sizes
$h1SizeL: 6rem
$h1SizeM: 4.5rem
$h1SizeS: 3.2rem

$h2Size: 4.5rem

$bodySizeS: 1.4rem
$bodySizeM: 1.6rem
$buttonSize: 1.4rem

// transition times
$transition2s: all .2s ease-in-out
$transition3s: all .3s ease-in-out

// dashboard layout
$menu--big: 22.5rem
$menu--small: 9rem

$white: #fff
$black: #000
$blackishBlue: #131A23

$text-color: #292929

$blue: #216fc9
$blue-lighter: #0076fe
$light-blue: #0295fd
$ultra-light-blue: #f5f9ff
$dark-blue: #0a1535
$chill-blue: #6f7693

$blue-gradient: linear-gradient(to right, $blue, $light-blue)
$light-blue-gradient: linear-gradient(to right, #a8d1ff, #c7e1ff)

$red: #cf0000
$ultra-light-red: #ffe3e3

$dark-yellow: #7a6504
$ultra-light-yellow: #faefbe

$green: #03b500
$ultra-light-green: #d9ffd9

$grey: #d8d8d8
$ultra-light-grey: #ebebeb
$dark-grey: #868686

$shadow-blue: rgba(0, 118, 254, .6)
$shadow-dark-blue: rgba(56, 116, 255, .3)
$box-shadow: 0 .4rem 1.6rem 0 rgba(17, 34, 17, .02)