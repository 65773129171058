@import '../../../../styles/Variables.module'

.animation--overlay
    position: relative
    z-index: 99999

.modal--container
    position: fixed
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    width: 100vw
    height: 100vh
    background: rgba(0, 0, 0, .8)
    z-index: 99999

    .modal--container__inner
        width: 100%
        max-width: 50rem
        background: $white
        padding: 2.4rem
        border-radius: 1.4rem

        .modal--header
            display: flex
            gap: 2rem
            align-items: center
            justify-content: space-between
            padding-bottom: 2.4rem
            margin-bottom: 3.2rem
            border-bottom: .1rem solid $grey

        .close--button
            width: 2.5rem
            height: 2.5rem
            display: flex
            align-items: center
            justify-content: center
            color: $blue
            border: .1rem solid $blue
            border-radius: .5rem
            cursor: pointer
            transition: $transition2s

            &:hover
                color: $white
                background: $blue

        .title
            font-size: 2.6rem

        .input--field
            position: relative

            .label
                position: absolute
                top: 50%
                left: 1.6rem
                font-size: $bodySizeS
                transform: translateY(-50%)
                cursor: text
                user-select: none
                transition: $transition2s

                &.filled
                    font-size: 1.2rem
                    top: 0
                    left: 1.1rem
                    background: $white
                    padding: .2rem .6rem
                    border-radius: 1rem

            .input
                width: 100%
                font-size: $bodySizeS
                padding: 1.2rem 1.6rem
                border: .1rem solid $grey
                border-radius: .6rem
                outline: unset
                transition: $transition2s

                &:focus
                    border-color: $blue

                &:focus + .label
                    font-size: 1.2rem
                    top: 0
                    left: 1.1rem
                    background: $white
                    padding: .2rem .6rem
                    border-radius: 1rem

        .field-error
            color: $red
            font-size: 1.2rem
            line-height: 1.4rem
            font-weight: 500
            margin-top: .5rem

        .modal--form
            display: flex
            flex-direction: column
            gap: 2rem

        .row
            display: flex
            gap: 2rem

            .column
                display: flex
                flex-direction: column
                width: 100%

        .modal--save
            max-width: 15rem
            display: inline-flex
            justify-content: center
            gap: 1rem
            font-size: $bodySizeS
            color: $blue
            background: transparent
            padding: 1.4rem 2rem
            border: .1rem solid $blue
            border-radius: 1rem
            cursor: pointer
            transition: $transition2s

            &:hover
                color: $white
                background: $blue