@import '../../../../styles/Variables.module'

.menu--section
    max-width: 25%
    min-width: 25rem
    flex-basis: 25%

    .menu--title
        font-size: 3.2rem
        margin-bottom: 1.6rem

    .nav--menu
        display: flex
        flex-direction: column
        justify-content: space-between
        gap: 1.7rem
        height: calc(100% - 12rem)
        background: $white
        padding: 3.2rem 2.4rem
        border-radius: 1.6rem
        box-shadow: $box-shadow

        .links--items    
            margin-bottom: 1.5rem

            .section
                display: flex
                flex-direction: column
                gap: 1.7rem

                &:not(:last-child)
                    padding-bottom: 2.4rem
                    margin-bottom: 2.4rem
                    border-bottom: .1rem solid rgba(0, 0, 0, .1)

        .logout--section
            padding-top: 3.2rem
            border-top: .1rem solid #d7e2ee