@import '../../../../styles/Variables.module'

.pagination
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 5px
    margin-top: 20px

    .pagination-btn
        width: 30px
        height: 30px
        color: $white
        background: $blue
        font-size: 10px
        border-radius: 5px
        border: 0
        outline: 0
        user-select: none
        cursor: pointer
        transition: .2s ease-in-out

        &.disabled
            opacity: .6
            cursor: not-allowed

        &:hover
            background: $black

            &.disabled
                background: $blue

    .pagination-controller
        display: flex
        align-items: center
        gap: 10px
        margin: 0 10px

        .pagination-search
            max-width: 4rem
            height: 3rem
            color: $black
            background: transparent
            font-size: 14px
            font-weight: 500
            text-align: center
            padding: 4px
            border: 1px solid $blue
            border-radius: 5px
            outline: 0
            transition: .2s ease-in-out

            &:focus
                box-shadow: 0 0 1px 3px rgba(0, 118, 254, .4)
                
            .pagination-total
                font-size: 14px
                font-weight: 500