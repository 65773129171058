@import '../../../styles/Variables.module'

.footer--container
    font-size: 1.4rem
    background: $dark-blue
    padding: 22.5rem 0 2.9rem 0

    .footer--container__second
        display: flex
        flex-direction: column
        align-items: center
        gap: 1rem
        color: $white
        text-align: center
        margin-top: 6rem
        padding-top: 2.5rem
        border-top: .1rem solid $chill-blue

        .policies
            display: flex
            flex-wrap: wrap
            justify-content: center
            gap: 1rem

            .link
                color: $white

@media all and (min-width: 768px)
    .footer--container
        .footer--container__second
            flex-direction: row
            justify-content: space-between
            gap: 2rem