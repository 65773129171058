@import '../../../styles/Variables.module'

.top-bar
    display: flex
    justify-content: flex-end
    background: $black
    padding: 10px 20px

    .top-bar--links
        display: flex
        gap: 15px

        .top-bar--link
            position: relative
            text-decoration: none

            .icon
                display: block
                color: $white
                font-size: 22px

            .notification-number
                position: absolute
                top: -6px
                right: -6px
                display: flex
                align-items: center
                justify-content: center
                width: 8px
                height: 8px
                color: $white
                background: $red
                font-size: 10px
                font-weight: 600
                padding: 5px
                border-radius: 100%