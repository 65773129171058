@import '../../styles/Variables.module'
    
.form-page
    ::placeholder
        color: $light-blue
    
.form-page
    display: flex
    align-items: center
    justify-content: center
    gap: 10rem
    height: calc(100vh - 4rem)
    padding: 2rem
    background: $light-blue-gradient

    .form-image
        width: 100%
        max-width: 40rem

    .recovery-menu
        .back-to-main
            display: inline-block
            color: $blue
            font-size: 1.3rem
            line-height: 1.6rem
            font-weight: 500
            margin-bottom: 1rem
            cursor: pointer

            &:hover
                text-decoration: underline
                text-underline-offset: .2rem

        .recovery-instructions
            font-size: 1.2rem
            line-height: 1.4rem
            font-weight: 500
            font-style: italic
            margin-bottom: 1rem

    .form, .recovery-menu
        width: 100%
        max-width: 32.5rem
        background: $white
        padding: 5rem
        border-radius: .5rem
        box-shadow: 0 0 3rem $shadow-dark-blue

        .form-title
            color: $blue
            font-size: 3.6rem
            line-height: 4rem
            text-transform: capitalize
            margin-bottom: 1rem

        .form-title--description
            font-size: 1.4rem
            margin-bottom: 3rem

        .account-type
            display: flex
            justify-content: space-between
            gap: 1rem
            margin-bottom: 2rem !important

        .form-field
            position: relative

            &:not(:last-child)
                margin-bottom: 1rem

            &.captcha-field
                display: flex
                align-items: center
                justify-content: space-between
                gap: 1rem

            &.input-error
                margin-top: -.5rem
                margin-bottom: .5rem

            .icon
                position: absolute
                top: 50%
                left: 1.5rem
                color: $blue
                font-size: 2rem
                transform: translateY(-50%)

            .form-input
                width: 100%
                font-size: 1.4rem
                line-height: 1.8rem
                color: $blue
                background: $ultra-light-blue
                border: 0
                border-bottom: .2rem solid $ultra-light-blue
                border-radius: .5rem
                padding: 1.4rem 1.4rem 1.2rem 4.8rem
                outline: unset
                box-sizing: border-box
                transition: .2s ease-in-out

                &:focus
                    border-bottom: .2rem solid $blue

                &.red
                    border-color: $red

            .captcha
                font-size: 1.4rem
                line-height: 1.8rem

            .captcha-input
                max-width: 8rem
                text-align: center
                padding: 1.4rem

            .form-button
                width: 100%
                color: $white
                background: $blue-gradient
                text-transform: uppercase
                font-weight: 600
                letter-spacing: .05rem
                padding: 1.5rem 1rem
                margin-top: 1.5rem
                border: unset
                border-radius: .5rem
                outline: unset
                box-shadow: 0 .5rem 2rem $shadow-blue
                cursor: pointer
                transition: .2s ease-in-out

                &.button-account
                    width: 100%
                    font-size: 1.2rem
                    line-height: 1.6rem
                    text-transform: capitalize

                    &:hover
                        box-shadow: 0 .75rem 2rem $shadow-blue

                    &.inactive
                        opacity: .6

                &[disabled]
                    opacity: .6
                    cursor: not-allowed

                &:hover
                    box-shadow: 0 1rem 2rem $shadow-blue

                &:focus
                    border-bottom: 0

        .field-error
            color: $red
            font-size: 1.2rem
            line-height: 1.4rem
            font-weight: 500
            margin-top: .5rem

        .message
            font-size: 1.2rem
            line-height: 1.4rem
            font-weight: 500
            margin-top: 3rem
            padding: .6rem 1.2rem
            border-radius: .5rem

            &.color-red
                color: $red
                background: $ultra-light-red

            &.color-green
                color: $green
                background: $ultra-light-green

        .account-util
            font-size: 1.3rem
            line-height: 1.6rem
            font-weight: 500
            text-align: center
            margin-top: 3rem

        .options-available
            display: flex
            flex-direction: column
            gap: 1.5rem

            .option
                display: flex
                flex-direction: column
                background: $ultra-light-blue
                padding: 1rem 1.25rem
                border-radius: .5rem
                border-bottom: .3rem solid $blue
                box-shadow: 0 .1rem .5rem $shadow-dark-blue
                cursor: pointer
                transition: .2s ease-in-out

                &:hover
                    color: $white
                    background: $light-blue

                .button-title
                    font-weight: 700

                .button-description
                    font-size: 1.3rem

        .registration-confirmation
            text-align: center

            .image
                max-width: 20rem
                margin-bottom: 2rem

            .title
                color: $blue
                font-size: 2.2rem
                line-height: 1.25
                margin-bottom: 1rem

            .info
                font-size: 1.4rem
                line-height: 1.8rem