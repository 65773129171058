@import '../styles/Variables.module'

.page--size
    max-width: $pageWidth
    padding: 0 2rem
    margin: 0 auto

.booking--negative_margin
    position: relative
    margin-top: -12.3rem
    z-index: 9999

.page--gap
    padding: 3rem 0

.page--gap__XL
    padding: 6rem 0

.page--inner__no-banner
    margin-top: 15rem

.button
    display: flex
    align-items: center
    justify-content: center
    gap: 1rem
    color: $blue
    background: transparent
    font-size: $bodySizeS
    font-weight: 500
    padding: 1.2rem 2.4rem
    border: .1rem solid $blue
    border-radius: .4rem
    outline: unset
    cursor: pointer
    transition: $transition2s

    &:hover
        color: $white
        background: $blue

.module--title
    font-family: $fontMontserrat
    font-size: 3.2rem
    font-weight: 600
    margin-bottom: unset !important

.module--description
    max-width: 75rem
    font-family: $fontMontserrat
    font-weight: 500
    margin-top: 1.6rem
    margin-bottom: 3.2rem

.enabled, .disabled, .pending, .paid, .rejected, .canceled
    display: inline-block
    width: max-content
    font-size: 1.2rem
    font-weight: 600
    padding: .5rem 1rem
    border-radius: 1rem
    text-transform: capitalize

.enabled, .pending
    color: $green
    background: $ultra-light-green

.disabled, .canceled
    color: $red
    background: $ultra-light-red

.paid
    color: $blue
    background: $ultra-light-blue

.rejected
    color: $dark-yellow
    background: $ultra-light-yellow

.input--field
    position: relative

    .label-outside
        display: block
        font-size: 1.2rem
        margin-bottom: .25rem

    .label
        position: absolute
        top: 50%
        left: 1.6rem
        font-size: $bodySizeS
        transform: translateY(-50%)
        cursor: text
        user-select: none
        transition: $transition2s

        &.filled
            font-size: 1.2rem
            top: 0
            left: 1.1rem
            background: $white
            padding: .2rem .6rem
            border-radius: 1rem

    select
        padding-block: 1.1rem !important

    .input
        width: 100%
        font-size: $bodySizeS
        padding: 1.2rem 1.6rem
        border: .1rem solid $grey
        border-radius: .6rem
        outline: unset
        transition: $transition2s

        &:focus
            border-color: $blue

        &:focus + .label
            font-size: 1.2rem
            top: 0
            left: 1.1rem
            background: $white
            padding: .2rem .6rem
            border-radius: 1rem

.message
    font-size: 1.2rem
    line-height: 1.4rem
    font-weight: 500
    padding: .6rem 1.2rem
    border-radius: .5rem

    &.message--margin-top
        max-width: max-content
        margin-top: 2rem

    &.red
        color: $red
        background: $ultra-light-red

    &.green
        color: $green
        background: $ultra-light-green

.order--page
    margin-bottom: 5rem

    .page--panel
        background: $white
        padding: 3.2rem 2.4rem
        border-radius: 1.6rem
        box-shadow: $box-shadow

.profile--flex
    display: flex
    gap: 5rem
    margin-bottom: 12rem

    .profile--page
        max-width: 75%
        flex-basis: 75%
        height: 100%

        .page--title
            font-size: 3.2rem
            margin-bottom: 1.6rem

        .page--panel
            background: $white
            padding: 3.2rem 2.4rem
            border-radius: 1.6rem
            box-shadow: $box-shadow

.password--container
    .password--title
        margin-bottom: 2rem

    .password--description
        color: $chill-blue
        font-size: $bodySizeS

.password--form__container
    display: flex
    gap: 2rem

    .password--form
        max-width: 45%
        flex-basis: 45%
        display: flex
        flex-direction: column
        gap: 2rem
        margin-top: 3.5rem

        .field-error
            color: $red
            font-size: 1.2rem
            line-height: 1.4rem
            font-weight: 500
            margin-top: .5rem

        .button
            margin-top: 3rem

    .password--image
        max-width: 55%
        flex-basis: 55%
        display: flex
        align-items: center
        justify-content: center
        margin-bottom: -3.2rem

        img
            max-width: 35rem

.twofactor--container
    .status
        text-align: center

        .status--image
            max-width: 30rem
            margin-bottom: 3.2rem

        .status--text
            font-size: 3rem
            margin-bottom: 3.2rem

    .otp--image
        max-width: 15rem
        margin: 2rem auto

    .otp--error
        color: $red
        font-size: 1.2rem
        line-height: 1.4rem
        font-weight: 500
        margin-top: 0.5rem

    .title
        text-align: center
        margin-bottom: 2rem

    .description
        max-width: 50rem
        color: $chill-blue
        font-size: 1.4rem
        text-align: center
        margin: 0 auto

    .steps
        display: flex
        flex-direction: column
        gap: 4rem

        .step
            display: flex
            gap: 2rem
            align-items: flex-start

            .step--number
                display: flex
                align-items: center
                justify-content: center
                min-width: 4rem
                height: 4rem
                color: $white
                background: $blue
                border-radius: 100%

            .step--content
                width: 100%
                display: flex
                flex-direction: column
                gap: 1rem
                color: $chill-blue
                font-size: 1.4rem

    .otp--form
        .input--container
            width: 100%
            display: flex
            gap: 1rem
            align-items: center
            justify-content: space-between
            background: rgba($grey, .6)
            border-radius: 1rem
            padding-inline: 1rem
            margin-bottom: 1rem
            box-sizing: border-box

            .otp--input
                width: 100%
                color: $blackishBlue
                background: transparent
                font-size: $bodySizeS
                border: unset
                outline: unset
                padding-block: 1.4rem

            .otp--button
                color: $white
                background: $blue
                font-size: $bodySizeS
                border: unset
                outline: unset
                padding: .6rem 1.2rem
                border-radius: 5rem
                cursor: pointer

.twofactor--methods
    display: flex
    flex-direction: column
    gap: .5rem
    margin-top: 5rem

    .box--method
        display: flex
        gap: 2rem
        align-items: center
        justify-content: space-between
        background: $dark-blue
        padding: 1.2rem 1.6rem
        border-radius: .5rem
        cursor: pointer
        transition: $transition2s

        .box--content
            display: flex
            align-items: center
            gap: 1.6rem
            color: $white
            user-select: none

            .bubble--icon
                width: 7rem
                height: 6rem

            .information
                display: flex
                flex-direction: column
                gap: .5rem

                .type
                    font-size: 2.2rem
                    font-weight: 500

                .hint
                    font-size: 1.4rem

        .box--switch
            .switch
                position: relative
                width: 7rem
                height: 3.2rem
                border-radius: 10rem
                transition: $transition2s

                .dot
                    position: absolute
                    top: .85rem
                    width: 2.4rem
                    height: 2.4rem
                    border-radius: 100%
                    transition: $transition2s

                &.disabled
                    background: rgba($red, .2)
                    box-shadow: 0 0 1rem .4rem $red

                    .dot
                        right: .8rem
                        background: $red

                &.enabled
                    background: rgba($green, .2)
                    box-shadow: 0 0 1rem .4rem $green

                    .dot
                        right: calc(100% - 2.4rem - .8rem)
                        background: $green

.module--title
    margin-bottom: 2rem

.tour--container
    .tour--header
        display: flex
        gap: 4rem
        align-items: flex-start
        justify-content: space-between

        .tour--details
            .title
                font-family: $fontMontserrat
                font-size: 3.4rem
                font-weight: 600
                line-height: 1.5

            .destination, .days
                display: flex
                gap: .5rem
                font-family: $fontMontserrat
                font-size: 1.4rem
                font-weight: 500
                margin-bottom: .5rem

                .icon
                    width: 1.6rem
                    color: $blue
                    font-size: $bodySizeM
                    text-align: center

        .tour--utils
            .price
                color: $blue
                font-size: 3.4rem
                font-weight: bold
                text-align: right

                .from
                    color: $dark-grey
                    font-size: 1.4rem
                    font-weight: normal
                    margin-right: .5rem

                .no-price
                    font-size: $bodySizeM
                    text-align: right

            .buttons
                display: flex
                gap: 1rem

            .button
                color: $white
                background: $blue

                &:hover
                    background: $blackishBlue
                    border-color: $blackishBlue
                    text-decoration: unset

    .tour--gallery
        display: grid
        grid-gap: .8rem
        grid-template-columns: repeat(4, 1fr)
        margin-top: 3.2rem
        border-radius: 1rem
        overflow: hidden

        .image
            overflow: hidden

            img
                display: block
                width: 100%
                height: 100%
                object-fit: cover
                aspect-ratio: 16 / 9
                transition: $transition3s

                &:hover
                    transform: scale(1.2)

            &:first-child
                grid-column: span 2
                grid-row: span 2

    .divider
        width: 100%
        height: .1rem
        background: $grey
        margin-block: 6.4rem

    .tour--map
        width: 100%
        border-radius: 1rem
        overflow: hidden

        iframe
            width: 100%
            max-height: 35rem

    .tour--booking-form
        .booking--form
            display: grid
            grid-gap: 1.6rem
            align-items: flex-end
            grid-template-columns: calc(40% - 1.6rem) calc(20% - 1.6rem) calc(20% - 1.6rem) 20%
            background: $white
            padding: 3.2rem 2.4rem
            border-radius: 1rem
            box-shadow: $box-shadow

        .button
            width: 100%

/* CHECKOUT LAYOUT */
.checkout--layout
    display: flex
    gap: 3rem

    .divider
        width: 100%
        height: .1rem
        background: $grey
        margin-block: 2.4rem

    .checkout--details
        max-width: 65%
        flex-basis: 65%

    .checkout--summary
        max-width: 35%
        flex-basis: 35%

    .checkout--finalstep
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        background: $blue-gradient !important
        padding: 10rem !important

        .icon
            color: $white
            font-size: 6rem
            margin-bottom: 2rem

        .text
            color: $white
            font-size: 2.4rem

    .checkout--section, .checkout--finalstep
        background: $white
        padding: 2.4rem
        border-radius: 1.2rem
        box-shadow: $box-shadow

        &:not(:last-child)
            margin-bottom: 3rem

        .section--title
            font-size: 3.2rem
            font-weight: bold
            margin-bottom: 2.4rem

        .subtitle
            font-size: 1.8rem
            font-weight: bold
            margin-bottom: 1rem

        .payment--methods
            display: flex
            gap: .5rem
            flex-direction: column

            .method
                position: relative
                padding: 1.6rem
                border-radius: 1.2rem
                cursor: pointer
                transition: $transition2s

                &::after
                    content: ''
                    position: absolute
                    top: 50%
                    right: 3rem
                    width: 1.8rem
                    height: 1.8rem
                    border: .2rem solid $blue
                    border-radius: 100%
                    transform: translateY(-50%)
                    transition: $transition2s

                &.active,
                &:hover
                    background: $blue

                    &::after
                        background: $white
                        border: .2rem solid $white
                        box-shadow: inset 0 0 0 .3rem $blue

                    .title,
                    .description
                        color: $white

                .title
                    font-size: 2.4rem
                    font-weight: 600
                    margin-bottom: .4rem
                    transition: $transition2s

                .description
                    font-size: 1.4rem
                    transition: $transition2s

        .section--row
            &:not(:last-child)
                margin-bottom: 2.4rem

            .invoicing--type
                display: flex
                gap: 2rem
                margin-bottom: 2rem

                [type='checkbox'] + label
                    margin-top: unset

        .details--row
            display: flex
            gap: 1rem
            align-items: center

            .row--label
                min-width: 5.5rem

            &.details--row:not(:last-child)
                margin-bottom: 1rem

            .details
                width: 100%
                display: flex
                gap: 1rem

                .input--field
                    flex-grow: 1

    .summary--heading
        display: flex
        gap: 2.4rem
        margin-bottom: 2.4rem

        .image
            max-width: 11rem
            min-width: 11rem
            min-height: 11rem
            border-radius: 1.2rem
            overflow: hidden

            img
                width: 100%
                height: 100%
                object-fit: cover

        .details
            .tour--title
                flex-grow: 1
                font-size: 2rem
                font-weight: 600
                margin-bottom: 1.2rem

            .tour--destination
                display: flex
                gap: .6rem
                align-items: center
                color: $blue

                .icon
                    font-size: 1.4rem

    .price--calculation,
    .summary--tour
        .coupon--form
            display: flex
            gap: 1rem

            .input
                width: 100%
                font-size: 1.4rem
                border: .1rem solid $grey
                border-radius: 2.2rem
                padding: 1rem 2rem
                outline: unset
                transition: $transition2s

                &:focus
                    border-color: $blue

            .button
                border-radius: 2.2rem

        .coupon--message
            font-size: 1.2rem
            margin-top: .5rem

            &.success
                color: $green

            &.failure
                color: $red

        .title
            margin-bottom: 1rem

            &:not(:first-child)
                margin-top: 2.4rem

        .price--row
            display: flex
            gap: 2rem
            justify-content: space-between

            &.total--price
                .label,
                .price
                    font-size: 2rem

            &:not(:last-child)
                margin-bottom: .5rem

            .label
                font-size: 1.4rem
                font-weight: 400

            .price
                font-size: 1.4rem
                font-weight: bold

.checkout--services
    background: $white
    padding: 2.4rem
    border-radius: 1.2rem
    box-shadow: $box-shadow

    .title
        margin-bottom: 1rem

    .service--box
        display: flex
        align-items: center
        justify-content: space-between
        font-size: $bodySizeS

        label
            width: 100%
            display: flex
            gap: 2rem
            align-items: center
            justify-content: space-between
            margin-top: .5rem

            &::before
                top: initial

            &::after
                top: initial
                bottom: .4rem

            .price
                min-width: 4.5rem
                color: $white
                background: $blue
                font-size: 1.2rem
                font-weight: 600
                text-align: center
                border-radius: .6rem
                letter-spacing: .1rem
                padding: .2rem

.book--button
    color: $white
    background: $blue
    margin-top: 3rem
    transition: $transition2s

    &:hover
        background: $blackishBlue
        border-color: $blackishBlue

/* ORDER DETAILS PAGE */
.box-title
    display: flex
    gap: 1rem
    align-items: center
    justify-content: space-between
    color: $blue
    font-size: $bodySizeM
    text-transform: uppercase
    border-bottom: .2rem solid $ultra-light-grey
    padding-bottom: 1.6rem
    margin-bottom: 1.6rem

.details--invoice
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 3rem
    margin-bottom: 4rem

.status--dropdown
    display: block
    width: 100%
    color: $blue
    background: $ultra-light-grey
    padding: 1rem
    margin-bottom: 1rem
    border: .1rem solid $ultra-light-grey
    border-radius: .6rem
    outline: unset
    cursor: pointer

    option
        background: $white

.update--status-btn
    display: block
    width: 100%
    min-height: 4.5rem
    color: $white
    background: $blue
    font-size: $bodySizeS
    padding: 1rem
    border: .1rem solid $blue
    border-radius: .6rem
    cursor: pointer
    transition: $transition2s

    &:hover
        background: $blackishBlue
        border-color: $blackishBlue

.invoice--buttons
    display: flex
    gap: 1rem
    justify-content: flex-end
    margin-top: 3rem

    .button
        color: $white
        background: $blue
        font-size: $bodySizeS
        padding: 1.5rem 2.5rem
        border: .1rem solid $blue
        border-radius: .6rem
        cursor: pointer
        transition: $transition2s

        &:hover
            background: $blackishBlue
            border-color: $blackishBlue

.order--details-box
    &.travellers
        margin-bottom: 4rem

    &.price-breakdown
        .box--content
            .row-total
                justify-content: flex-end

    .order--edit-btn
        display: inline-flex
        gap: .5rem
        background: unset
        font-size: $bodySizeS
        border: unset
        cursor: pointer

    .box--content
        display: flex
        flex-direction: column
        gap: .5rem

        .divider
            border-bottom: .2rem solid $ultra-light-grey
            margin-block: 1.6rem

        .row
            display: flex
            gap: .5rem
            align-items: center

            .label, .value
                font-size: $bodySizeS

            .label
                font-weight: bold

.filters-and-tours
    display: flex
    gap: 2.4rem
    margin-top: 3.2rem

    .filters
        flex-basis: 23%

    .tours
        flex-basis: 77%

    .no-tours
        background: $white
        border-radius: 1.6rem
        padding: 2rem

    .filters
        height: fit-content
        background: $white
        padding: 2.4rem
        border-radius: 1.2rem
        box-shadow: $box-shadow

        .container
            .title
                font-family: $fontMontserrat
                font-weight: 600
                margin-bottom: 2rem

            .filter--item:not(:last-child)
                padding-bottom: 3.5rem
                margin-bottom: 3rem
                border-bottom: .2rem solid $ultra-light-grey

                .filter--title
                    font-size: $bodySizeM
                    font-family: $fontMontserrat
                    font-weight: 600
                    margin-bottom: 1rem

                .filter--items
                    display: flex
                    flex-direction: column
                    gap: .5rem

                    label
                        margin-top: unset

    .tour
        display: flex
        background: $white
        border-radius: 1.2rem
        box-shadow: $box-shadow
        overflow: hidden

        .image-container
            position: relative
            max-width: 30rem

            .image
                img
                    display: block
                    width: 100%
                    height: 100%
                    object-fit: cover
                    aspect-ratio: 1 / 1

            .image-counter
                position: absolute
                top: 1.2rem
                right: 1.2rem
                color: $blackishBlue
                background: rgba(255, 255, 255, .5)
                font-family: $fontMontserrat
                font-size: 1.2rem
                font-weight: 600
                padding: .8rem
                border-radius: .8rem

        .info
            width: 100%
            display: flex
            flex-direction: column
            padding: 2.4rem

            .info--header
                display: flex
                gap: 4rem
                justify-content: space-between

                .title
                    font-family: $fontMontserrat
                    font-size: 2.4rem
                    font-weight: 600

                .price
                    min-width: 12rem
                    
                    .from
                        font-size: 1.2rem

                    .final-price
                        display: flex
                        color: $blue
                        font-family: $fontMontserrat
                        font-size: 2.6rem
                        font-weight: 600

                        .unit
                            font-size: 2rem
                            margin-top: .5rem

            .info--content
                flex-grow: 1
                margin-top: 1rem

                .about
                    &:not(:last-child)
                        margin-bottom: .5rem

                    .icon
                        width: 1.6rem
                        color: $blue
                        text-align: center
                        margin-right: 1rem

                    .text
                        font-family: $fontMontserrat
                        font-size: 1.3rem
                        font-weight: 500

                    .date
                        &:not(:last-child)::after
                            content: ','
                            margin-right: .5rem

            .info--footer
                display: flex
                gap: 1.6rem
                border-top: .2rem solid $ultra-light-grey
                padding-top: 2.4rem
                
                .link-to-tour
                    flex-grow: 1
                    display: block
                    color: $white
                    background: $blue
                    font-size: $bodySizeS
                    text-align: center
                    padding: 1.55rem
                    border-radius: .4rem
                    transition: $transition3s

                    &:hover
                        color: $white
                        background: $blackishBlue
                        text-decoration: unset

        &:not(:last-child)
            margin-bottom: 2.4rem

.no-data
    font-size: $bodySizeS