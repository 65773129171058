@import '../styles/Variables.module'

.page--body
    display: flex

    .page--container
        width: 100%
        
        .container
            padding: 2rem

.page-not-found
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    min-height: calc(100% - 113px)

    .error-code
        font-size: 100px
        font-weight: 700
    
    .error-text
        font-size: 20px

a
    color: $blue

    &:hover
        text-decoration: underline
        text-underline-offset: 2px

.margin-bottom-s
    margin-bottom: 2rem

/* ORDER DETAILS PAGE */
.order--container
    display: grid
    grid-template-columns: 80% calc(20% - 3rem)
    grid-gap: 3rem
    background: $white
    border: .1rem solid $ultra-light-grey
    padding: 2rem
    margin: 2rem

    .status-message
        color: $green
        font-size: $bodySizeS
        font-weight: 500
        margin-top: 1rem

    .box-title
        display: flex
        gap: 1rem
        align-items: center
        justify-content: space-between
        color: $blue
        font-size: $bodySizeM
        text-transform: uppercase
        border-bottom: .2rem solid $ultra-light-grey
        padding-bottom: 1.6rem
        margin-bottom: 1.6rem

    .details--invoice
        display: grid
        grid-template-columns: repeat(3, 1fr)
        grid-gap: 3rem
        margin-bottom: 4rem

    .status--dropdown
        display: block
        width: 100%
        color: $blue
        background: $ultra-light-grey
        padding: 1rem
        margin-bottom: 1rem
        border: .1rem solid $ultra-light-grey
        border-radius: .6rem
        outline: unset
        cursor: pointer

        option
            background: $white

    .update--status-btn
        display: block
        width: 100%
        min-height: 4.5rem
        color: $white
        background: $blue
        font-size: $bodySizeS
        padding: 1rem
        border: .1rem solid $blue
        border-radius: .6rem
        cursor: pointer
        transition: $transition2s

        &:hover
            background: $blackishBlue
            border-color: $blackishBlue

    .invoice--buttons
        display: flex
        gap: 1rem
        justify-content: flex-end
        margin-top: 3rem

        .button
            color: $white
            background: $blue
            font-size: $bodySizeS
            padding: 1.5rem 2.5rem
            border: .1rem solid $blue
            border-radius: .6rem
            cursor: pointer
            transition: $transition2s

            &:hover
                background: $blackishBlue
                border-color: $blackishBlue