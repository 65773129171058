@import '../../../../styles/Variables.module'

.booking--form
    background: $white
    padding: 3.2rem 2.4rem
    border-radius: 1.6rem
    box-shadow: $box-shadow

    .title
        font-family: $fontMontserrat
        font-size: 2rem
        font-weight: 600
        margin-bottom: 2rem

    .booking--button
        display: flex
        justify-content: flex-end

        .button
            display: flex
            gap: 1rem
            color: $white
            background: $blue
            padding: 1.6rem
            margin-top: 2rem
            border: .1rem solid $blue
            border-radius: .4rem
            cursor: pointer
            transition: $transition2s

            &:hover
                color: $white
                background: $blackishBlue
                border-color: $blackishBlue

    .inputs
        display: flex
        gap: 1.6rem
        align-items: center
        justify-content: center

        & > div
            width: 100%

@media all and (max-width: 768px)
    .booking--form
        .inputs
            flex-direction: column