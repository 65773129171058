@import '../../../../styles/Variables.module'

.newsletter--container
    position: relative
    margin-bottom: -16.45rem
    display: flex
    flex-direction: column
    justify-content: space-between
    background: $blue
    padding-inline: 2.4rem
    padding-top: 2.4rem
    border-radius: 2rem

    .newsletter--content
        display: flex
        flex-direction: column
        justify-content: space-between
        padding-bottom: 2.4rem

        .title
            max-width: 30rem
            color: $white
            font-size: 5.4rem
            font-style: italic

        .newsletter--bottom
            .caption
                color: $white
                margin-bottom: 1.6rem

            .form
                display: grid
                grid-template-columns: auto
                grid-gap: 1.6rem

                .input
                    width: 100%
                    color: $blackishBlue
                    padding: 1.8rem 1.6rem
                    border: unset
                    outline: unset
                    border-radius: .4rem

                .submit
                    min-width: 10.5rem
                    color: $white
                    background: $dark-blue
                    font-size: $bodySizeS
                    font-weight: 600
                    letter-spacing: .05rem
                    padding: 1.8rem 1.6rem
                    border: unset
                    outline: unset
                    border-radius: .4rem
                    cursor: pointer
                    transition: $transition2s

                    &:hover
                        color: $blackishBlue
                        background: $white

    .mailbox-icon
        display: flex
        max-width: 25rem
        align-items: flex-end

        img
            display: block

@media all and (min-width: 768px)
    .newsletter--container
        flex-direction: row
        gap: 5rem

        .newsletter--content
            .newsletter--bottom
                .form
                    grid-template-columns: calc(80% - 1.6rem) 20%

        .mailbox-icon
            max-width: unset

.newsletter--message
    display: inline-block
    color: $white
    font-size: 1.2rem
    padding: .4rem 1rem
    margin-top: 1rem
    border-radius: 1rem

    &.valid
        background: $green

    &.invalid
        background: $red