@import '../../../../styles/Variables.module'

.navbar--container
    position: relative
    background: rgba(255, 255, 255, .7)
    box-shadow: 0 .4rem 3rem rgba(0, 0, 0, 0.05)
    backdrop-filter: blur(5.2px)
    padding-block: 1.5rem
    z-index: 1

    .navbar
        display: flex
        align-items: center
        justify-content: space-between
        flex-direction: column
        gap: 1rem
        padding: 0 2rem

    .navbar--links
        display: flex
        align-items: center
        gap: 3.2rem

        .link
            color: $blackishBlue
            font-size: $buttonSize
            font-weight: 600
            transition: $transition2s

            &.signup--button
                color: $white
                background: $blue
                padding: 1.5rem 2.4rem
                border-radius: .8rem

                &:hover
                    color: $white
                    background: $blackishBlue

            &:hover
                text-decoration: unset

        .profile
            position: relative

            .profile--button
                display: flex
                align-items: center
                gap: 1.5rem
                cursor: pointer
                user-select: none

                .picture
                    width: 4.5rem
                    height: 4.5rem
                    border-radius: 100%

                .name
                    font-weight: 600

            .profile--dropdown
                opacity: 0
                visibility: hidden
                position: absolute
                top: 6.5rem
                right: 0
                width: 25rem
                background: $white
                padding: 3.2rem
                border-radius: 1.2rem
                box-shadow: 0 .2rem 2rem 0 rgba(0, 0, 0, .05)
                user-select: none
                transition: $transition2s

                &.active
                    opacity: 1
                    visibility: visible

                .row
                    display: flex
                    flex-direction: column
                    gap: 1.7rem

                    &:not(:last-child)
                        padding-bottom: 2.4rem
                        margin-bottom: 2.4rem
                        border-bottom: .1rem solid rgba(0, 0, 0, .1)

                .picture-name
                    display: flex
                    flex-direction: row
                    align-items: center
                    
                    .name
                        font-size: 1.8rem
                        font-weight: 600

                    .picture
                        width: 5.5rem
                        height: 5.5rem
                        border-radius: 100%

@media all and (min-width: 480px)
    .navbar--container
        padding-block: 2.5rem

        .navbar
            gap: 2rem
            flex-direction: row