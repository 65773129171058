@import '../../../styles/Variables.module'

.animation--overlay
    position: relative
    z-index: 99999

.modal--container
    position: fixed
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    width: 100vw
    height: 100vh
    background: rgba(0, 0, 0, .8)
    z-index: 99999

    .modal--container__inner
        width: 100%
        background: $white
        padding: 2.4rem
        border-radius: 1.4rem
        
        &.container--M
            max-width: 50rem

        &.container--XXL
            max-width: 110rem

        .modal--header
            display: flex
            gap: 2rem
            align-items: center
            justify-content: space-between
            padding-bottom: 2.4rem
            margin-bottom: 3.2rem
            border-bottom: .1rem solid $grey

        .close--button
            width: 2.5rem
            height: 2.5rem
            display: flex
            align-items: center
            justify-content: center
            color: $blue
            border: .1rem solid $blue
            border-radius: .5rem
            cursor: pointer
            transition: $transition2s

            &:hover
                color: $white
                background: $blue

        .title
            font-size: 2.6rem

        .input--field
            position: relative

            label
                margin: unset

            .label-outside
                display: block
                font-size: 1.2rem
                margin-bottom: .25rem

            .label
                position: absolute
                top: 50%
                left: 1.6rem
                font-size: $bodySizeS
                transform: translateY(-50%)
                cursor: text
                user-select: none
                transition: $transition2s

                &.filled
                    font-size: 1.2rem
                    top: 0
                    left: 1.1rem
                    background: $white
                    padding: .2rem .6rem
                    border-radius: 1rem

            .input
                width: 100%
                font-size: $bodySizeS
                padding: 1.2rem 1.6rem
                border: .1rem solid $grey
                border-radius: .6rem
                outline: unset
                transition: $transition2s

                &:focus
                    border-color: $blue

                &:focus + .label
                    font-size: 1.2rem
                    top: 0
                    left: 1.1rem
                    background: $white
                    padding: .2rem .6rem
                    border-radius: 1rem

        .field-error
            color: $red
            font-size: 1.2rem
            line-height: 1.4rem
            font-weight: 500
            margin-top: .5rem

        .modal--form
            display: flex
            flex-direction: column
            gap: 2rem
            max-height: 80vh
            overflow-y: auto
            padding: 1rem 0
            padding-inline: 1rem
            margin-inline: -1rem

            &::-webkit-scrollbar
                width: 1rem

            &::-webkit-scrollbar-track
                background: $grey

            &::-webkit-scrollbar-thumb
                background: $blue

        .dynamically--columns
            display: flex
            flex-wrap: wrap
            gap: 2rem

            .column
                width: unset !important
                flex-direction: row !important
                align-items: center
                gap: .5rem

                &.date--container
                    border: .1rem solid $grey
                    border-radius: .6rem
                    padding-right: 1.5rem

                    .input
                        padding-right: 1rem
                        border: unset

        .preview
            max-width: 25rem
            margin-top: 1rem

        .delete--button
            display: flex
            align-items: center
            justify-content: center
            color: $white
            background: $red
            width: 2.5rem
            height: 2rem
            font-size: 1.2rem
            font-weight: bold
            border-radius: .5rem
            cursor: pointer

        .row
            display: flex
            gap: 2rem
            align-items: flex-end

            .column
                width: 100%
                display: flex
                flex-direction: column

            .input--field
                width: 100%

                .services--layout
                    display: flex
                    flex-wrap: wrap

                    .service--item
                        max-width: 25%
                        flex-basis: 25%

        .modal--save
            max-width: 15rem
            display: inline-flex
            justify-content: center
            gap: 1rem
            font-size: $bodySizeS
            color: $blue
            background: transparent
            padding: 1.4rem 2rem
            border: .1rem solid $blue
            border-radius: 1rem
            cursor: pointer
            transition: $transition2s

            &:hover
                color: $white
                background: $blue