@import '../../../styles/Variables.module'

.order--details-box
    &.travellers
        margin-bottom: 4rem

    &.price-breakdown
        .box--content
            .row-total
                justify-content: flex-end

    .order--edit-btn
        display: inline-flex
        gap: .5rem
        background: unset
        font-size: $bodySizeS
        border: unset
        cursor: pointer

    .box--content
        display: flex
        flex-direction: column
        gap: .5rem

        .divider
            border-bottom: .2rem solid $ultra-light-grey
            margin-block: 1.6rem

        .row
            display: flex
            gap: .5rem

            .label, .value
                font-size: $bodySizeS

            .label
                font-weight: bold

.no-data
    font-size: $bodySizeS