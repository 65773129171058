@import '../../../../styles/Variables.module'

.account--information
    max-width: 75%
    flex-basis: 75%
    height: 100%

    .account--tabs
        display: flex
        background: $white
        border-radius: 1.6rem
        box-shadow: $box-shadow

        .tab--button
            position: relative
            max-width: 33.3333333%
            flex-basis: 33.3333333%
            padding: 3rem 2.4rem
            cursor: pointer

            &.active, &:hover
                &::after
                    position: absolute
                    content: ''
                    bottom: 0
                    left: 2.4rem
                    width: calc(100% - 4.8rem)
                    height: .4rem
                    background: $blue

            &:not(:last-child)
                &::before
                    position: absolute
                    content: ''
                    top: 1.5rem
                    right: 0
                    width: .1rem
                    height: calc(100% - 3rem)
                    background: #d7e2ee

            .text
                font-weight: 600

    .account--panels
        margin-top: 4rem