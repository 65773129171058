@import '../../../../styles/Variables.module'

.nav--menu
    background: rgba(255, 255, 255, .7)
    box-shadow: 0 .4rem 3rem rgba(0, 0, 0, 0.05)
    backdrop-filter: blur(5.2px)
    padding-block: 1.5rem

    .nav--items
        display: flex
        gap: 2rem
        justify-content: flex-end

        .item
            color: $blackishBlue
            transition: $transition2s

            &:hover
                color: $blue
                text-decoration: unset