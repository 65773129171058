@import '../styles/Variables.module'

.title
    font-size: 1.8rem
    text-transform: uppercase
    margin-bottom: 1rem

.text
    font-size: $bodySizeS

.document--header
    display: flex
    gap: 2rem
    align-items: center
    justify-content: space-between

    .client
        text-align: left
        margin-top: 2rem

    .company-logo
        max-width: 25rem

        img
            max-width: 100%
            height: auto

    .company-details
        text-align: right

.document--title
    font-size: 3.2rem
    text-align: center
    text-transform: uppercase
    margin: 6rem 0 0

.document--subtitle
    font-size: 2.2rem
    text-align: center

.document--date
    text-align: center
    font-weight: 500
    margin-top: 1rem

.document--content
    font-size: $bodySizeS
    margin-top: 4rem

    .invoice-from
        font-size: $bodySizeM
        text-align: center
        font-weight: bold
    
    .invoice--heading
        display: grid
        grid-template-columns: calc(75% - 2rem) 25%
        grid-gap: 2rem
        color: $white
        background: $blue
        font-weight: bold
        text-transform: uppercase
        border-radius: .6rem
        padding: 2rem
        margin-top: 1rem

    .invoice--body
        padding: 2rem
        
        .divider
            border-bottom: .2rem solid $ultra-light-grey
            margin: 2rem -2rem

        .body-price
            display: grid
            grid-template-columns: calc(75% - 2rem) 25%
            grid-gap: 2rem

        .paragraph
            margin: unset !important

        .tour-title
            font-weight: bold
            text-transform: uppercase

        .trip-date
            margin-bottom: 1.5rem !important

        .adults, .childs
            display: flex
            flex-direction: column
            gap: .25rem

        .childs
            margin-top: 1rem !important

        .total-price
            font-size: $bodySizeM
            font-weight: bold

    .section:not(:last-child)
        margin-bottom: 3rem

    .title
        font-size: $bodySizeS
        text-transform: uppercase
        margin-bottom: 1rem

    .paragraph
        page-break-inside: avoid
        page-break-after: auto

    .paragraph:not(:last-child)
        margin-bottom: 1rem

    .invoice--footer
        font-size: 1.3rem
        font-weight: 600
        text-align: center

        .law
            margin-bottom: 1rem !important

.signature--footer
    display: flex
    gap: 2rem
    justify-content: space-between
    margin: 5rem 5rem 0 5rem

    .agency-signature, .traveller-signature
        .text
            text-align: center