@import '../../../styles/Variables.module'
    
.admin--menu
    display: flex
    flex-direction: column
    min-width: $menu--big
    min-height: calc(100vh - 4.2rem)
    background: $black
    transition: $transition3s

    .items--section
        &:not(:last-child)
            margin-bottom: 2rem

        .section--title
            display: block
            color: $dark-grey
            font-size: $bodySizeS
            margin: .5rem 1.5rem

    &.menu--small
        min-width: $menu--small

        .section--title
            text-align: center

        .link
            justify-content: center
            gap: 0

            .link--text
                transition: opacity 0s ease-in-out, visibility 0s ease-in-out
                width: 0
                opacity: 0
                visibility: hidden
                transition-delay: 0

    .link
        display: flex
        align-items: center
        gap: 1rem
        color: $white
        font-size: $bodySizeS
        padding: 1.2rem
        box-shadow: inset 0 0 0 $blue
        transition: .1s ease-in-out

        &:hover, &.active
            background: rgba(255, 255, 255, .15)
            box-shadow: inset 5px 0 0 $blue
            text-decoration: none

        .icon
            min-width: 36px
            font-size: 16px
            text-align: center

        .link--text
            transition: .3s ease-in-out
            transition-delay: .3s