@import '../../../../styles/Variables.module'
.bookings
    .label-headings, .item
        display: grid
        grid-template-columns: 50% 23% 15% 12%

    .label-headings
        font-size: $bodySizeS
        font-weight: bold
        border-bottom: .2rem solid $ultra-light-grey
        padding-bottom: 2.4rem
        margin-bottom: 2.4rem

    .order-items
        .item
            color: inherit
            align-items: center
            font-size: $bodySizeS
            padding: .5rem 1rem
            margin-inline: -1rem
            border-radius: .6rem
            transition: $transition2s
            cursor: pointer

            &:hover
                background: $ultra-light-grey
                text-decoration: inherit
