@import '../../../../../styles/Variables.module'

.avatar--container
    position: relative
    text-align: center
    margin-top: -8.4rem

    .avatar--image
        max-width: 16rem
        margin: 0 auto 2.4rem auto
        
        img
            display: block
            box-shadow: inset 0 0 0 .4rem $blackishBlue
            border: .4rem solid $blackishBlue
            border-radius: 100%

    .avatar--details
        color: $blackishBlue
        margin-bottom: 3.2rem

        .name
            font-size: 2.4rem
            font-weight: bold
            margin-bottom: .8rem   