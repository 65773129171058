@import '../../../styles/Variables.module'

.box-container
    display: grid
    grid-gap: 2rem
    margin-bottom: 2rem

    &.box-grid-2
        grid-template-columns: repeat(2, 1fr)

    &.box-grid-3
        grid-template-columns: repeat(3, 1fr)

    &.box-grid-4
        grid-template-columns: repeat(4, 1fr)

    &.box-grid-5
        grid-template-columns: repeat(5, 1fr)

    .box
        display: flex
        align-items: center
        justify-content: space-between
        gap: 20px
        background: $white
        padding: 20px
        border: 1px solid $ultra-light-grey
        border-radius: 5px
        user-select: none
        transition: .3s ease-in-out

        &:hover
            box-shadow: 0 10px 20px rgba(0, 118, 254, .1)
            transform: translateY(-5px)

        .counter
            font-size: 30px
            font-weight: 800

        .text
            color: $dark-grey

        .box--icon
            display: flex
            align-items: center
            justify-content: center
            min-width: 45px
            height: 45px
            color: $white
            background: $blue-gradient
            border-radius: 10px
            box-shadow: 0 0 15px rgba(0, 118, 254, .3)