@import '../../../styles/Variables.module'

.loading
    display: flex
    gap: 1rem
    align-items: center
    margin-top: 2rem

.table
    background: $white
    border: .1rem solid $ultra-light-grey
    border-radius: .5rem
    box-shadow: 0 0 1.5rem rgba(0, 118, 254, .3)
    overflow: hidden

    .table--header
        display: flex
        gap: 2rem
        align-items: center
        justify-content: space-between
        background: $blue
        padding: 2rem

        .title
            color: $white
            font-size: 2rem

        .button
            color: $blue
            background: $white
            font-size: $bodySizeS
            font-weight: bold
            padding: .6rem 1.2rem
            border: unset
            outline: unset
            border-radius: 1rem
            cursor: pointer
            transition: $transition2s

            &:hover
                color: $white
                background: $blackishBlue

    .table--body
        padding: 2rem

        .table--body__header, .table--body__content
            .row
                display: grid
                align-items: center

            &.users--table
                .row
                    grid-template-columns: 5% 20% 30% 15% 11% 11% 8%

            &.tours--table
                .row
                    grid-template-columns: 5% 10% 30% 20% 10% 17% 8%

            &.destinations--table
                .row
                    grid-template-columns: 5% 10% 77% 8%

            &.newsletter--table
                .row
                    grid-template-columns: 5% 40% 20% 20% 15%

            &.coupons--table
                .row
                    grid-template-columns: 5% 57% 15% 15% 8%

            &.additional-services--table
                .row
                    grid-template-columns: 5% 72% 15% 8%

            &.orders--table
                .row
                    grid-template-columns: 5% 20% 20% 10% 12% 15% 10% 8%

        .table--body__header
            .heading
                color: $dark-grey
                font-size: 1.4rem
                border-bottom: .1rem solid $ultra-light-grey
                padding: 0 1rem 2rem 1rem

        .table--body__content
            .row
                padding-block: 1.5rem

                img
                    display: block
                    max-width: 14rem
                    max-height: 8rem
                    object-fit: cover
                    border-radius: 1rem

                &:not(:last-child)
                    border-bottom: .1rem solid $ultra-light-grey
                
                &:last-child
                    padding-bottom: 0

                .text
                    color: $black
                    padding: 0 1rem

                    &.text--details
                        color: $dark-grey
                        font-size: 1.2rem
                        font-weight: 500

                    .tools
                        display: flex
                        gap: 1rem

                    .tool--button
                        display: inline-flex
                        gap: .5rem
                        align-items: center
                        color: $white
                        background: $blackishBlue
                        font-size: 1.2rem
                        font-weight: 600
                        padding: .5rem 1rem
                        border-radius: 1rem
                        cursor: pointer
                        user-select: none
                        transition: $transition2s

                        &:hover
                            background: $blue

                    .enabled, .disabled, .pending, .paid, .rejected, .canceled
                        display: inline-block
                        font-size: 1.2rem
                        font-weight: 600
                        padding: .5rem 1rem
                        border-radius: 1rem
                        text-transform: capitalize

                    .enabled, .pending
                        color: $green
                        background: $ultra-light-green
                        

                    .disabled, .canceled
                        color: $red
                        background: $ultra-light-red

                    .paid
                        color: $blue
                        background: $ultra-light-blue

                    .rejected
                        color: $dark-yellow
                        background: $ultra-light-yellow


            .no-data-found
                margin-top: 2rem

.action--button
    color: $white
    background: $blue
    font-size: 1.2rem
    font-weight: 500
    border: .1rem solid $blue
    border-radius: .6rem
    padding: 1rem 1.6rem
    margin-bottom: 2rem
    cursor: pointer
    transition: $transition2s

    &:hover
        background: $blackishBlue
        border-color: $blackishBlue

    &:not(:last-child)
        margin-right: 1rem