@import '../../../styles/Variables.module'

.utility-bar
    display: flex
    align-items: center
    justify-content: space-between
    background: $white
    padding: 1.5rem 2rem
    border-bottom: .1rem solid $ultra-light-grey

.utility-bar--responsive-menu
    display: flex
    align-items: center
    justify-content: center
    width: 30px
    height: 30px
    cursor: pointer

    .utility-bar--hamburger
        position: relative
        width: 30px
        height: 4px
        background: $black
        border-radius: 100px

    .utility-bar--hamburger::before,
    .utility-bar--hamburger::after
        content: ''
        position: absolute
        right: 0
        height: 4px
        background: $black
        border-radius: 100px

    .utility-bar--hamburger::before
        top: -7px
        width: 20px

    .utility-bar--hamburger::after
        top: 7px
        width: 25px

.utility-bar--utils
    display: flex
    align-items: center
    gap: 30px

    .title
        font-size: 26px