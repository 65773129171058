@import '../../../../styles/Variables.module'
    
.profile--button
    display: flex
    gap: 1rem
    align-items: center
    justify-content: space-between
    color: $blackishBlue
    transition: $transition2s
    cursor: pointer

    &.active
        color: $blue

    &:hover
        color: $blue
        text-decoration: unset

    .with-icon
        display: flex
        align-items: center
        gap: 1rem

        .icon
            width: 2rem
            text-align: center

    .arrow
        font-size: 1rem