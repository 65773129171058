@import '../../../../styles/Variables.module'

$headerHeightL: 70rem
$headerHeightM: 45rem
    
.banner--container
    position: relative

    &.banner--L
        height: $headerHeightL

        .banner--title
            top: 60%

    &.banner--M
        height: $headerHeightM

        .banner--title
            top: 65%

    &::before
        content: ''
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: linear-gradient(to right, rgba(0, 35, 77, .63), rgba(0, 35, 77, 0))

    .banner--title
        position: absolute
        transform: translateY(-50%)

        .banner--title__inner
            .title, .subtitle
                color: $white

            .title
                max-width: calc(122rem / 2)
                font-family: $fontMontserrat
                font-weight: 600

            .subtitle
                font-family: $fontHankenGrotesk
                font-size: 2.4rem
                font-weight: 400
                margin-top: 2.5rem