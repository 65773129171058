@import '../../../../styles/Variables.module'

.grid-card--image
    display: grid
    grid-gap: 2rem
    grid-template-columns: repeat(4, 1fr)

    .card
        display: flex
        flex-direction: column
        justify-content: flex-end
        position: relative
        height: 35rem
        padding: 2.4rem
        background-size: cover
        background-position: center
        border-radius: 1.2rem
        overflow: hidden
        z-index: 0

        &::before
            content: ''
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            background: inherit
            background-size: inherit
            background-position: inherit
            background-repeat: no-repeat
            border-radius: inherit
            z-index: -1
            transition: $transition3s

        &::after
            content: ''
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            background: linear-gradient(0deg, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, 0) 100%)
            z-index: -1

        &:hover::before
            transform: scale(1.1)

        .title
            color: $white
            font-size: 2.4rem
            font-weight: 600

        .button
            color: $white
            background: $blue-lighter
            font-size: 1.4rem
            font-weight: 500
            text-align: center
            padding: 1.55rem
            margin-top: 1.6rem
            border-radius: .4rem
            transition: $transition2s

            &:hover
                color: $blackishBlue
                background: $white
                text-decoration: unset

@media all and (max-width: 1024px)
    .grid-card--image

        grid-template-columns: repeat(3, 1fr)

@media all and (max-width: 768px)
    .grid-card--image
        grid-template-columns: repeat(2, 1fr)

@media all and (max-width: 480px)
    .grid-card--image
        grid-template-columns: auto
