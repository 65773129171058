@import '../../../../styles/Variables.module'

.error-not-found
    display: flex
    flex-direction: column
    align-items: center
    padding-block: 10rem

    .title
        font-family: $fontMontserrat
        margin-bottom: 2rem

    .description
        font-size: 2.4rem