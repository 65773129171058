@import '../../../../styles/Variables.module'
    
.wishlist
    width: 4.85rem
    height: 4.85rem
    color: $blue
    background: transparent
    font-size: 2rem
    border: .1rem solid $blue
    border-radius: .4rem
    cursor: pointer
    transition: $transition3s

    & > i
        display: inline-block
        margin-top: .4rem

    &:hover, &.active
        color: $white
        background: $blue

.wishlist--page
    color: $blue
    background: transparent
    font-size: 1.2rem
    font-weight: 600
    border: .1rem solid $blue
    border-radius: .4rem
    padding: .4rem .8rem
    cursor: pointer
    transition: $transition2s

    &:hover
        color: $white
        background: $blue