@import '../../../../../styles/Variables.module'

.panel--container
    .panel--title
        display: flex
        align-items: center
        justify-content: space-between
        gap: 2rem
        font-size: 3.2rem
        margin-bottom: 1.6rem

        .save--button
            .button
                display: flex
                align-items: center
                gap: 1rem
                color: $blue
                background: transparent
                font-size: $bodySizeS
                font-weight: 500
                padding: 1.2rem 2.4rem 1.2rem 2rem
                border: .1rem solid $blue
                border-radius: .4rem
                outline: unset
                cursor: pointer
                transition: $transition2s

                &:hover
                    color: $white
                    background: $blue

    .panel--data
        display: flex
        flex-direction: column
        gap: 2.6rem
        background: $white
        padding: 3.2rem 2.4rem
        border-radius: 1.6rem
        box-shadow: $box-shadow

        .row
            display: flex
            align-items: center
            justify-content: space-between
            gap: 2rem

            .info
                display: flex
                flex-direction: column
                gap: .4rem
                color: $blackishBlue

                .label 
                    opacity: .75
                    
                .text
                    font-size: 2rem
                    font-weight: 600

.message
    font-size: 1.2rem
    line-height: 1.4rem
    font-weight: 500
    padding: .6rem 1.2rem
    border-radius: .5rem

    &.color-red
        color: $red
        background: $ultra-light-red

    &.color-green
        color: $green
        background: $ultra-light-green