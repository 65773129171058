@import '../admin/Admin.module'
@import '../client/Client.module'
@import './Variables.module'

*
    margin: 0
    padding: 0

html
    font-size: 10px
    scroll-behavior: smooth
    scroll-padding-top: 10rem

body
    color: $text-color
    background: $ultra-light-blue
    font-family: $fontHankenGrotesk
    font-size: $bodySizeM
    line-height: 1.25

h1
    font-size: $h1SizeS
    line-height: 1

@media all and (min-width: 480px)
    h1
        font-size: $h1SizeM

@media all and (min-width: 768px)
    h1
        font-size: $h1SizeL

input, select, option, textarea, button
    font-family: $fontHankenGrotesk
    font-size: $bodySizeM
    line-height: 1.25
    box-sizing: border-box

::placeholder
    color: $blackishBlue

a
    text-decoration: unset

img
    width: 100%

ul, li, ol
    list-style-type: none

[type="checkbox"]
    display: none

    & + label
        position: relative
        display: inline-block
        font-size: 1.4rem
        line-height: 1.8rem
        padding-left: 2.5rem
        margin-top: 1rem
        cursor: pointer
        user-select: none

        &::before
            content: ''
            position: absolute
            top: .2rem
            left: 0
            width: 1.4rem
            height: 1.4rem
            background: $white
            outline: .1rem solid $blue
            border-radius: .2rem

        &::after
            content: ''
            position: absolute
            top: .4rem
            left: .2rem
            width: 1.2rem
            height: 1.2rem
            background-image: url('../../public/assets/icons/icon_checkmark.svg')
            background-size: 1rem
            background-repeat: no-repeat
            opacity: 0
            transform: scale(0)
            transition: all .3s ease-in-out

    &:checked
        & + label
            &::before
                background: $blue

            &::after
                opacity: 1
                transform: scale(1)

/* WORKSPACE CLASSES */
.margin--no-top
    margin-top: unset !important

.mb--20
    margin-bottom: 2rem

/* COLOR CLASSES */
.color--green
    color: $green

.color--red
    color: $red

.background--light-green
    background: $ultra-light-green

.background--light-red
    background: $ultra-light-red

/* VALIDATION CLASSES */
.validation--message
    font-size: 1.2rem
    line-height: 1.4rem
    font-weight: 500
    margin-top: 3rem
    padding: .6rem 1.2rem
    border-radius: .5rem

.validation--error
    color: $red
    font-size: 1.2rem
    line-height: 1.4rem
    font-weight: 500
    margin-top: 0.5rem

/* OTHER CLASSES */
.button
    color: $white
    background: $blue
    font-size: 1.2rem
    padding: .6rem 1.2rem
    margin-top: 1rem

.no-data--message
    margin-block: 2rem

/* IMAGE GALLERY ADMIN */
.image--gallery
    display: flex
    flex-wrap: wrap
    gap: 1rem

    .item
        .upload-button
            width: 20rem
            height: 12rem
            display: inline-flex
            align-items: center
            justify-content: center
            background: $ultra-light-grey
            font-size: 2.4rem
            cursor: pointer
            border-radius: 1rem
            transition: $transition2s

            &:hover
                background: $grey

        .upload-input
            display: none

        .image-uploaded
            position: relative
            width: 100%
            height: 100%
            max-width: 20rem
            max-height: 12rem
            border-radius: 1rem
            overflow: hidden

            img
                width: 100%
                height: 100%
                object-fit: cover

            .delete-image
                position: absolute
                top: 0
                right: 0
                width: 2.5rem
                height: 2.5rem
                line-height: 2.5rem
                color: $white
                background: $red
                font-size: 1.2rem
                font-weight: bold
                text-align: center
                border-radius: 0 0 0 1rem
                cursor: pointer