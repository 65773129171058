@import '../../../../styles/Variables.module'

.latest--grid
    display: grid
    grid-template-columns: 45% calc(55% - 1.6rem)
    grid-gap: 1.6rem

    .background
        display: flex
        flex-direction: column
        color: $white
        background: $blue
        padding: 2.4rem
        border-radius: 2rem

        .header
            display: flex
            gap: 4rem
            justify-content: space-between

            .title
                font-family: $fontMontserrat
                font-size: 4rem
                font-weight: 600

            .price
                display: flex
                align-items: center
                justify-content: center
                flex-direction: column
                width: 6.8rem
                height: 6.2rem
                color: $blackishBlue
                background: $white
                font-family: $fontMontserrat
                font-size: 2rem
                font-weight: bold
                padding: .8rem
                border-radius: .8rem

                .from
                    font-size: $bodySizeM
                    font-weight: normal

        .body
            flex-grow: 1
            font-size: $bodySizeS
            margin-block: 2.4rem

        .footer
            .button
                display: block
                color: $blackishBlue
                background: $white
                font-family: $fontMontserrat
                font-size: $bodySizeS
                font-weight: 500
                text-align: center
                padding: 1.55rem
                border-radius: .4rem
                transition: $transition2s

                &:hover
                    color: $white
                    background: $blackishBlue
                    text-decoration: unset

    .gallery
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 1.6rem

        .image
            border-radius: 1.2rem
            overflow: hidden

            img
                display: block
                width: 100%
                height: 100%
                object-fit: cover
                transition: $transition3s

                &:hover
                    transform: scale(1.1)

@media all and (max-width: 768px)
    .latest--grid
        grid-template-columns: auto

        .gallery
            grid-template-columns: auto