@import '../../../../styles/Variables.module'

.footer--links__grid
    display: grid
    grid-gap: 3rem

    .column
        color: $blackishBlue

        .title
            color: $chill-blue
            font-size: $bodySizeM
            margin-bottom: 1.6rem

        .links--stack
            display: inline-flex
            flex-direction: column
            gap: 1.2rem

            .link
                color: $white
                font-size: $bodySizeS

@media all and (min-width: 480px)
    .footer--links__grid
        grid-template-columns: repeat(2, 1fr)

@media all and (min-width: 768px)
    .footer--links__grid
        grid-template-columns: repeat(3, 1fr)

@media all and (min-width: 1024px)
    .footer--links__grid
        grid-template-columns: repeat(5, 1fr)