@import '../../../../styles/Variables.module'

.social-media--container
    display: flex
    flex-direction: column
    align-items: center
    gap: 2rem
    margin-bottom: 6rem

    .footer--logo
        max-width: 24rem

    .social-icons
        display: flex
        flex-wrap: wrap
        gap: 1rem

        .icon
            width: 3rem
            height: 3rem
            color: $blackishBlue
            background: $white
            line-height: 3rem
            text-align: center
            border-radius: .5rem
            transition: $transition2s

            &:hover
                color: $white
                background: $blue

@media all and (min-width: 550px)
    .social-media--container
        flex-direction: row
        gap: 4rem