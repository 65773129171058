@import '../../../../styles/Variables.module'
.wishlist
    .label-headings, .item
        display: grid
        grid-template-columns: 25% 38% 15% 12% 10%

    .label-headings
        font-size: $bodySizeS
        font-weight: bold
        border-bottom: .2rem solid $ultra-light-grey
        padding-bottom: 2.4rem
        margin-bottom: 2.4rem

    .tour-items
        .item
            color: inherit
            align-items: center
            font-size: $bodySizeS
            padding: .5rem 1rem
            margin-inline: -1rem
            border-radius: .6rem
            transition: $transition2s

            .tour-image
                max-width: 15rem
                border-radius: 1.2rem
                overflow: hidden

                img
                    display: block
                    object-fit: cover
                    aspect-ratio: 2 / 1